import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAPI } from "hooks";
import { FC, useEffect, useState } from "react";

export const LastGameMVP: FC<{}> = props => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [index, setIndex] = useState<number>(0);
  const [data, setData] = useState<{ username: string; name: string; tag: string; kills: number; }>(null);
  const { get } = useAPI();

  const load = async () => {
    setIsLoading(true);

    try {
      const response = await get('tournament/last/mvp');
      setIndex(response.index);
      setData(response.row);
    } catch (e) {
      console.log(e);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    load();
  }, []);

  return (
    <>
      {isLoading && <FontAwesomeIcon icon={faSpinner} className="text-7xl text-goat animate-spin" />}
      {!isLoading && data && <>
        <div className="text-5xl font-extrabold drop-shadow-goat">MVP QUEDA {index}</div>
        <div className="flex flex-col items-center gap-5">
          <div className="text-5xl font-extrabold"><span className="text-goat drop-shadow-goat">{data.username}</span> com <span className="text-goat drop-shadow-goat">{data.kills} abates</span></div>
          <div className="text-3xl font-extrabold">{data.tag} / {data.name}</div>
        </div>
      </>}
    </>
  );
}