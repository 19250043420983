import { LastGameMVP } from "pages/LastGameMVP";
import { LastGameResults } from "pages/LastGameResults";
import { Menu } from "pages/Menu";
import { OverallResults } from "pages/OverallResults";
import { FC } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

export const App: FC<{}> = props => {
  return (
    <>
      <img src="images/bg_circle.png" alt="Circle" className="fixed animate-spin-slow z-0" />
      <div className="flex flex-col items-center justify-center p-10 gap-20 z-10">
        <img src="images/logo.png" alt="Logo" className="drop-shadow-lg" />
        <BrowserRouter>
          <Routes>
            <Route index element={<Menu />} />
            <Route path="lastgame" element={<LastGameResults />} />
            <Route path="lastgamemvp" element={<LastGameMVP />} />
            <Route path="all" element={<OverallResults />} />
          </Routes>
        </BrowserRouter>
      </div>
    </>
  );
};