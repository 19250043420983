import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { useEffect } from 'react';
import { useBetween } from 'use-between';

const useAPIHook = () => {
  const processResponse = async <Res = any, Req = AxiosResponse<Res>, D = any>(response: AxiosResponse<Res, D>) => {
    const data = response.data;

    //@ts-ignore
    if (data.message)
      //@ts-ignore
      if (data.status)
        //@ts-ignore
        success(data.message);
      else
        //@ts-ignore
        error(data.message);

    //@ts-ignore
    return data.data;
  };

  const get = async <Req = any, Res = any>(url: string, config?: AxiosRequestConfig<Req>) => {
    try {
      const response = await axios.get<Res, AxiosResponse<Res>, Req>(`${window.AppConfig['api.url']}/${url}`, config);

      return processResponse<Res, AxiosResponse<Res>, Req>(response as AxiosResponse<Res, Req>);
    }

    catch (err) {
    }
  };

  const post = async <Req = any, Res = any>(url: string, data?: Req, config?: AxiosRequestConfig<Req>) => {
    try {
      const response = await axios.post<Res, AxiosResponse<Res>, Req>(`${window.AppConfig['api.url']}/${url}`, data, config);

      return processResponse<Res, AxiosResponse<Res>, Req>(response as AxiosResponse<Res, Req>);
    }

    catch (err) {
    }
  };

  useEffect(() => {

    axios.defaults.headers.common = { 'Password': window.AppConfig['api.password'] };

    return () => {
      axios.defaults.headers.common = {};
    }
  }, []);

  return { get, post };
}

export const useAPI = () => useBetween(useAPIHook);