import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAPI } from "hooks";
import { FC, useEffect, useState } from "react";
import { formatNumber } from "utils";

const DATA = [
  { "points": "12499", "name": "Bolotas", "tag": "BLT" },
  { "points": "12491", "name": "FAITH9", "tag": "F9" },
  { "points": "9175", "name": "PARMA", "tag": "GDP" },
  { "points": "8825", "name": "Underground", "tag": "UND" },
  { "points": "8609", "name": "Prodigys", "tag": "PDG" },
  { "points": "8601", "name": "70s", "tag": "70S" },
  { "points": "8451", "name": "AIMHORSE", "tag": "AIM" },
  { "points": "8435", "name": "BCB", "tag": "BCB" },
  { "points": "8434", "name": "eviL", "tag": "EVL" },
  { "points": "8272", "name": "Warning", "tag": "WNG" },
  { "points": "8159", "name": "Manchester City", "tag": "MCI" },
  { "points": "8145", "name": "agaxis", "tag": "HX" },
  { "points": "8111", "name": "VoltaVida", "tag": "VV" },
  { "points": "8109", "name": "7Lucky", "tag": "7L" },
  { "points": "8086", "name": "RASTA", "tag": "RAS" },
];

export const LastGameResults: FC<{}> = props => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [index, setIndex] = useState<number>(0);
  const [data, setData] = useState<{ name: string; tag: string; points: number; }[]>([]);
  const { get } = useAPI();

  const load = async () => {
    setIsLoading(true);

    try {
      const response = await get('tournament/last');
      setIndex(response.index);
      setData(response.rows);
    } catch (e) {
      console.log(e);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    load();
  }, []);

  return (
    <>
      {isLoading && <FontAwesomeIcon icon={faSpinner} className="text-7xl text-goat animate-spin" />}
      {!isLoading && <>
        <div className="text-5xl font-extrabold drop-shadow-goat">QUEDA {index}</div>
        <div className="grid grid-cols-3 gap-10">
          {data && data.map((row, i) => <div key={row.name} className="flex shadow-md">
            <div className="bg-goat w-14 h-14 flex justify-center items-center rounded-l-lg shrink-0 text-2xl">{i + 1}°</div>
            <div className="flex justify-center items-center bg-goat/10 px-4 text-2xl w-80">{row.tag} / {row.name}</div>
            <div className="bg-goat/40 grow px-3 h-14 flex justify-center items-center rounded-r-lg shrink-0 text-2xl">{formatNumber(row.points)} pts</div>
          </div>)}
        </div>
      </>}

    </>
  );
}