import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAPI } from "hooks";
import { FC, useEffect, useState } from "react";
import { formatNumber } from "utils";

export const OverallResults: FC<{}> = props => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<{ name: string; tag: string; points: number; }[]>([]);
  const { get } = useAPI();

  const load = async () => {
    setIsLoading(true);

    try {
      const response = await get('tournament/all');
      setData(response.rows);
    } catch (e) {
      console.log(e);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    load();
  }, []);

  return (
    <>
      {isLoading && <FontAwesomeIcon icon={faSpinner} className="text-7xl text-goat animate-spin" />}
      {!isLoading && <>
        <div className="text-5xl font-extrabold drop-shadow-goat">GERAL</div>
        <div className="grid grid-cols-3 gap-10">
          {data.map((row, i) => <div key={row.name} className="flex shadow-md">
            <div className="bg-goat w-14 h-14 flex justify-center items-center rounded-l-lg shrink-0 text-2xl">{i + 1}°</div>
            <div className="flex justify-center items-center bg-goat/10 px-4 text-2xl w-80">{row.tag} / {row.name}</div>
            <div className="bg-goat/40 grow px-3 h-14 flex justify-center items-center rounded-r-lg shrink-0 text-2xl">{formatNumber(row.points)} pts</div>
          </div>)}
        </div>
      </>}

    </>
  );
}