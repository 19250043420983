import { createRoot } from 'react-dom/client';
import { App } from './App';
import './index.css';

declare global {
  interface Window {
    AppConfig: { [key: string]: any };
  }
}

window.AppConfig = window.AppConfig || {};

createRoot(document.getElementById('root')).render(<App />);